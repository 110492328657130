(() => {
  const getPardotVisitorId = () => {
    if (window.pi?.tracker?.visitor_id) {
      return window.pi.tracker.visitor_id
    }
    return null;
  }

  const filterNullValuesFromObject = object => Object.fromEntries(Object.entries(object).filter(([_, v]) => v != null && v !== '' && typeof v !== 'undefined'));

  const processIdentify = (identity, object) => {
    if (object) window.HockeyStack.identify(filterNullValuesFromObject(object));
    if (identity) {
      window.HockeyStack.identify(identity);
      window.HockeyStack.goal('Pardot Form Submitted');
    }
  }

  const getData = () => {
    const pardotScriptLoaded = !!window.getPardotUrl;
    if (!(timeout > 5000 || pardotScriptLoaded)) {
      timeout += 50
      setTimeout(getData, 50)
      return;
    }

    const object = { pardot_visitor_id: getPardotVisitorId() };

    try {
      if (pardotScriptLoaded) {
        if (window.HockeyStack) return processIdentify(null, object);

        if (!Array.isArray(window.hockeystackQueue)) {
          window.hockeystackQueue = [];
        }
        window.hockeystackQueue.push(() => processIdentify(null, object));
      }
    } catch (error) {
    }
  }

  let timeout = 0
  getData();

  window.addEventListener('message', event => {
    if (event?.data?.message === 'PARDOT_DATA_READY') {
      let identity = null;
      Object.keys(event.data.data || {}).forEach(key => {
        if (key.toLowerCase().includes('email') && event.data.data?.[key]) {
          identity = event.data.data[key];
        }
      });

      if (!identity) return;

      if (typeof window.HockeyStack !== 'undefined') return processIdentify(identity);
      if (!Array.isArray(window.hockeystackQueue)) window.hockeystackQueue = [];
      window.hockeystackQueue.push(() => processIdentify(identity));
    }
  });
})();
